<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <template>
      <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Nueva Etapa' }]" />
      <v-layout row wrap>
        <v-flex md12 sm12 xs12>
          <v-card>
            <v-card-title primary-title>
              <span class="success--text font-weight-bold headline">Crear etapa</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-form
                ref="form"
                v-model="validForm"
                lazy-validation
                @submit.prevent="submitCreateEtapa"
              >
                <v-container fluid grid-list-lg>
                  <v-text-field
                    v-model="form.nombre"
                    :disabled="processingForm"
                    label="Nombre"
                    outline
                    :rules="rules.nombre"
                    :error="!!formErrors.nombre"
                    :error-messages="formErrors.nombre"
                    @keyup="
                      () => {
                        formErrors.nombre = undefined;
                        delete formErrors.nombre;
                      }
                    "
                  />
                  <v-layout row wrap>
                    <v-flex md12 sm12 xs12>
                      <v-text-field
                        v-model="form.descripcion"
                        :disabled="processingForm"
                        label="Descripcion"
                        outline
                        :rules="rules.descripcion"
                        :error="!!formErrors.descripcion"
                        :error-messages="formErrors.descripcion"
                        @keyup="
                          () => {
                            formErrors.descripcion = undefined;
                            delete formErrors.descripcion;
                          }
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-divider class="mb-3" />
                <div class="text-xs-center pb-3">
                  <v-btn
                    type="submit"
                    color="success"
                    :disabled="!validForm || processingForm"
                    :loading="processingForm"
                    >Guardar</v-btn
                  >
                  <v-btn color="error" @click="$router.push({ name: 'ListaEtapas' })"
                    >Cancelar</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Nueva Etapa" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },

  data() {
    return {
      formErrors: {},
      form: {
        nombre: "",
        descripcion: ""
      },
      validForm: true,
      processingForm: false,

      rules: {
        nombre: [v => !!v || "El nombre es requerido"],
        descripcion: [v => !!v || "El tipo es requerido"]
      }
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getEtapa({ etapaId: this.$route.params.id }).then(response => {
      const etapaInfo = response.data.data;
      this.setForm(etapaInfo);
    });
  },
  methods: {
    ...mapActions({
      updateEtapa: "etapas/updateEtapa",
      getEtapa: "etapas/getEtapa"
    }),
    setForm(etapa) {
      this.form.nombre = etapa.nombre;
      this.form.descripcion = etapa.descripcion;
    },
    submitCreateEtapa() {
      if (!this.$refs.form.validate()) return false;
      this.processingForm = true;
      this.updateEtapa({ etapaId: this.$route.params.id, data: this.form })
        .then(response => {
          this.processingForm = false;
          this.$router.push({ name: "ListaEtapas" });
        })
        .catch(error => {
          this.processingForm = false;
        });
    }
  }
};
</script>
